import { DIMENSION_TITLES } from './analytics'
import { DIMENSION_DEFINITION_BY_TITLE } from './analyticsDefinitions'
import type { TranslationKeys } from '~/plugins/i18n'
import { DataType } from '~/types/analytics'
import { RenamingField, RenamingType } from '~/types/renaming'

export const RENAMING_FIELD_DEFINITION_KEY: {
  [key in RenamingField]?: TranslationKeys | undefined
} = {
  [RenamingField.EMPLOYEE_SUBORGANIZATION]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.EMPLOYEE_SUBORGANIZATION]
      ?.definition,
  [RenamingField.EMPLOYEE_DEPARTMENT]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.EMPLOYEE_DEPARTMENT]
      ?.definition,
  [RenamingField.EMPLOYEE_LOCATION_OFFICE]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.EMPLOYEE_LOCATION_OFFICE]
      ?.definition,
  [RenamingField.EMPLOYEE_LOCATION_REMOTE]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.EMPLOYEE_LOCATION_REMOTE]
      ?.definition,
  [RenamingField.EMPLOYEE_CAREER_LEVEL]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.EMPLOYEE_CAREER_LEVEL]
      ?.definition,
  [RenamingField.EMPLOYEE_CAREER_SUB_LEVEL]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.EMPLOYEE_CAREER_LEVEL]
      ?.definition,
  [RenamingField.EMPLOYEE_GROUP_1]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.EMPLOYEE_GROUP_1]
      ?.definition,
  [RenamingField.EMPLOYEE_GROUP_2]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.EMPLOYEE_GROUP_2]
      ?.definition,
  [RenamingField.EMPLOYEE_GROUP_3]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.EMPLOYEE_GROUP_3]
      ?.definition,
  [RenamingField.EMPLOYEE_LOCATION_1]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.EMPLOYEE_LOCATION_1]
      ?.definition,
  [RenamingField.EMPLOYEE_LOCATION_2]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.EMPLOYEE_LOCATION_2]
      ?.definition,

  [RenamingField.RECRUITMENT_JOB_SUBORGANIZATION]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.JOB_SUBORGANIZATION]
      ?.definition,
  [RenamingField.RECRUITMENT_JOB_DEPARTMENT]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.JOB_DEPARTMENT]?.definition,
  [RenamingField.RECRUITMENT_JOB_GROUP_1]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.JOB_GROUP_1]?.definition,
  [RenamingField.RECRUITMENT_JOB_GROUP_2]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.JOB_GROUP_2]?.definition,
  [RenamingField.RECRUITMENT_JOB_GROUP_3]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.JOB_GROUP_3]?.definition,
  [RenamingField.RECRUITMENT_JOB_LOCATION_1]:
    DIMENSION_DEFINITION_BY_TITLE[DIMENSION_TITLES.JOB_LOCATION_1]?.definition,

  [RenamingField.RECRUITMENT_INTERVIEW_1]:
    'sharedAnalytics.interviewDefinition.interview1',
  [RenamingField.RECRUITMENT_INTERVIEW_2]:
    'sharedAnalytics.interviewDefinition.interview2',
  [RenamingField.RECRUITMENT_INTERVIEW_3]:
    'sharedAnalytics.interviewDefinition.interview3',
  [RenamingField.RECRUITMENT_INTERVIEW_4]:
    'sharedAnalytics.interviewDefinition.interview4',

  [RenamingField.RECRUITMENT_TIME_TO_OFFER]:
    'sharedAnalytics.timeToDefinition.timeToOffer',
  [RenamingField.RECRUITMENT_TIME_TO_HIRE]:
    'sharedAnalytics.timeToDefinition.timeToHire',
  [RenamingField.RECRUITMENT_TIME_TO_FIRST_FILL]:
    'sharedAnalytics.timeToDefinition.timeToFirstFill',
  [RenamingField.RECRUITMENT_TIME_TO_FILL_AND_ARCHIVE]:
    'sharedAnalytics.timeToDefinition.timeToFillAndArchive'
}

export const DATA_TYPE_BY_RENAMING_TYPE: {
  [key in RenamingType]: DataType
} = {
  [RenamingType.EMPLOYEE_DIMENSION]: DataType.EMPLOYEE,
  [RenamingType.RECRUITMENT_DIMENSION]: DataType.RECRUITMENT,
  [RenamingType.RECRUITMENT_INTERVIEW]: DataType.RECRUITMENT,
  [RenamingType.RECRUITMENT_TIME_TO]: DataType.RECRUITMENT
}
